const globalVar = typeof globalThis !== 'undefined' ? globalThis :
    typeof global !== 'undefined' ? global :
        typeof window !== 'undefined' ? window :
            typeof self !== 'undefined' ? self :
                {};
export { globalVar, };
if (typeof globalThis === 'undefined') {
    globalVar.globalThis = globalVar;
}
const stackGlobalsSymbol = Symbol.for('__stack-globals');
globalVar[stackGlobalsSymbol] ??= {};
export function createGlobal(key, init) {
    if (!globalVar[stackGlobalsSymbol][key]) {
        globalVar[stackGlobalsSymbol][key] = init();
    }
    return globalVar[stackGlobalsSymbol][key];
}
